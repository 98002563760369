import React, { useRef, useEffect, useState, useCallback } from 'react';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import Layout from '../components/Layout';
import SEO from '../components/SEO';
import '../styles/product.scss';
import Button from '../components/Button/Button';
import Accordion from '../components/Accordion/Accordion';

gsap.registerPlugin(ScrollTrigger);

const phoneTextContent = [
  {
    id: 0,
    content: (
      <p>
        It&apos;s <span className="gold">Seamless</span>
      </p>
    ),
  },
  {
    id: 1,
    content: (
      <p>
        It&apos;s <span className="gold"> Private </span>
      </p>
    ),
  },
  {
    id: 2,
    content: (
      <p>
        It&apos;s <span className="gold"> Unique </span>
      </p>
    ),
  },
];

function Product() {
  const tl = useRef();

  useEffect(() => {
    import('tiny-slider/src/tiny-slider').then(({ tns }) => {
      const valuesSliderContainer = document.querySelector('.values-slider');

      if (valuesSliderContainer) {
        tns({
          container: valuesSliderContainer,
          autoplay: false,
          autoplayButtonOutput: false,
          mouseDrag: true,
          loop: false,
          nav: false,

          gutter: 24,

          responsive: {
            0: {
              items: 1.2,
              controls: false,
            },

            768: {
              items: 2.2,
            },

            1200: {
              controls: true,
              items: 3,
            },
          },
        });
      }
    });
  }, []);

  useEffect(() => {
    ScrollTrigger.matchMedia({
      // large
      '(min-width:767px)': function () {
        const panels = gsap.utils.toArray('.phone-text-scroll-main');

        const lastPanel = panels[panels.length - 1];

        tl.current = gsap.timeline({
          scrollTrigger: {
            trigger: '.phonescroll-block-container',
            start: 'top top',
            end: '+=150%',
            scrub: 0.6,
            pin: true,
            // markers: true,
          },
        });

        panels.forEach((panel) => {
          const content = panel.querySelector('.text-scroll-wrapper');

          tl.current.fromTo(
            content,
            { y: '50%' },
            {
              y: '-20%',
            },
          );

          if (panel !== lastPanel) {
            tl.current.to(panel, {
              yPercent: -100,
              ease: 'none',
            });
          }
        });

        gsap.set('.phone-text-scroll-main', {
          zIndex: (i, target, targets) => targets.length - i,
        });
      },
    });
  }, [tl]);

  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh();
    }, 1200);
  }, []);

  const [phoneTextAnim, setphoneTextAnim] = useState(0);
  const updatePhoneTextAnim = useCallback(() => {
    setphoneTextAnim((state) => (state === 2 ? 0 : state + 1));
  }, []);

  useEffect(() => {
    const interval = setInterval(() => updatePhoneTextAnim(), 2200);

    return () => clearInterval(interval);
  }, [updatePhoneTextAnim]);

  return (
    <Layout lightHeader>
      <SEO
        title="How do we help you open a U.S. Bank Account? | Aeldra features"
        description="U.S. Bank Account from India · For Study Abroad Students · For International Travellers · For ESOPs · For Investors · For High Net-worth Individuals · 24*7 Concierge"
      />

      <section className="product-hero black-bg">
        <div className="container relative">
          <div className="row">
            <div className="col-md col-7">
              <h1>We are designed to make banking* abroad easier.</h1>
              <p>
                Because we know how good people are and how cumbersome banking*
                can be.
              </p>
            </div>
          </div>

          <div className="product-hero-img absolute">
            <img src="/img/product-hero.png" alt="" />
          </div>
        </div>
      </section>

      <section className="black-bg">
        <div className="phonescroll-block-container">
          <div className="phone-text-scroll-main">
            <div className="container">
              <h2 className="heading text-center" style={{ marginBottom: 20 }}>
                We offer a welcoming exclusivity
              </h2>
              <p className="subhead text-center" style={{ marginBottom: 80 }}>
                That comes at a price lower, way lower than the competition.{' '}
              </p>
              <div className="row">
                <div className="col-md col-8 offset-2">
                  <div className="flex justify-center product-page-phone-scroll">
                    <div className="phone-image">
                      <img src="/img/product-page-phone.png" alt="" />
                    </div>

                    <div className="phone-text-block-desc">
                      <div className="text-scroll-wrapper">
                        {phoneTextContent.map(({ id, content }) => (
                          <div
                            key={id}
                            className={`single-phone-text ${
                              id === phoneTextAnim ? 'active' : ''
                            }`}>
                            {content}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="black-bg section-gap">
        <div className="container">
          <div className="text-center">
            <h2 className="heading ">Bank the Aeldra Quantum Difference</h2>
            <Button href="https://prod.aeldra.com/get-started" whiteButton>
              Open an account
            </Button>
          </div>

          <table
            className="table product-gradient-table"
            cellPadding={0}
            cellSpacing={0}>
            <thead>
              <tr>
                <th>&nbsp;</th>
                <th>AELDRA QUANTUM</th>
                <th>MAJOR U.S. BANKS</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Account* Opening Fee</td>
                <td className="bold">Zero</td>
                <td>$0-$15</td>
              </tr>
              <tr>
                <td>Monthly Fee</td>
                <td className="bold">Zero</td>
                <td>$10-$25</td>
              </tr>
              <tr>
                <td>Overdraft Fee</td>
                <td className="bold">Zero</td>
                <td>Up To $35</td>
              </tr>
              <tr>
                <td>Incoming International Wire Fee</td>
                <td className="bold">Zero</td>
                <td>$15-$35</td>
              </tr>
              <tr>
                <td>Real-time Peer-to-Peer (AeldraPay) Fee</td>
                <td className="bold">Zero</td>
                <td>N/A</td>
              </tr>
              <tr>
                <td>Card^ Replacement Fee</td>
                <td className="bold">Zero</td>
                <td>Up To $20</td>
              </tr>
              {/* <tr>
                <td>Interest Rate</td>
                <td className="bold">Up To 2.02% APY</td>
                <td>0.05% - 0.50%</td>
              </tr> */}

              <tr>
                <td>Virtual Debit Card^</td>
                <td className="bold">√</td>
                <td>X</td>
              </tr>
              <tr>
                <td>Foreign Transaction Fee</td>
                <td className="bold">0%</td>
                <td>Up To 3.5%</td>
              </tr>
              {/* <tr>
                <td>Interest</td>
                <td className="bold">Up To 40x vs Major U.S. Banks</td>
                <td>-</td>
              </tr> */}
            </tbody>
          </table>
        </div>
      </section>

      <section
        className="bg-lightgrey section-pad"
        style={{ overflowX: 'hidden' }}>
        <div className="container">
          <h2 className="heading text-center">Coming Soon</h2>
          <p className="subhead text-center">
            We&apos;re constantly on the look-out for ramping up products that
            make cross-border banking* easier
          </p>
          <div className="slider-overflow-visible">
            <div className="values-slider equal-height ">
              <div className="item">
                <div className="icon-text-block white-bg">
                  <div className="icon-wrapper">
                    <svg width="20" height="20" fill="none" viewBox="0 0 20 20">
                      <path
                        fill="#1B1A1A"
                        d="M9.714 0a9.714 9.714 0 1 1 0 19.429A9.714 9.714 0 0 1 9.714 0Zm-3.42 13.714-3.508.001a8.012 8.012 0 0 0 5.205 3.813 16.4 16.4 0 0 1-1.696-3.813Zm6.84 0c-.4 1.338-.97 2.62-1.695 3.813a8.008 8.008 0 0 0 5.204-3.812h-3.509Zm-1.797 0H8.091c.378 1.126.92 2.24 1.623 3.342a15.202 15.202 0 0 0 1.623-3.342Zm.45-6.285H7.641a12.305 12.305 0 0 0 0 4.572h4.144c.286-1.51.286-3.062 0-4.572Zm-5.886 0H2.046a8.001 8.001 0 0 0-.332 2.285c0 .795.116 1.563.332 2.286h3.856a14.012 14.012 0 0 1 0-4.571H5.9Zm11.482 0h-3.856c.25 1.513.25 3.057 0 4.571h3.856a7.98 7.98 0 0 0 .331-2.286c0-.794-.115-1.56-.331-2.285ZM7.99 1.902l-.147.033a8.014 8.014 0 0 0-5.058 3.78h3.509c.38-1.292.946-2.563 1.696-3.813Zm1.724.47a15.188 15.188 0 0 0-1.623 3.343h3.246a15.185 15.185 0 0 0-1.623-3.342Zm1.724-.471.06.098a16.348 16.348 0 0 1 1.637 3.715h3.508a8.01 8.01 0 0 0-5.205-3.813Z"
                      />
                    </svg>
                  </div>

                  <h3 className="body-font">Investment account*</h3>
                  <p>
                    The end-all account* for your global banking* and investment
                    needs. Bank Account* + Mastercard^ Debit Card^ + SIPC
                    Insurance Account*. Launching Soon for customers who have an
                    Aeldra Quantum Account*
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon-text-block white-bg">
                  <div className="icon-wrapper">
                    <svg width="22" height="20" fill="none" viewBox="0 0 22 20">
                      <path
                        fill="#1B1A1A"
                        d="M16.775 0c1.585 0 2.16.165 2.74.475.58.31 1.033.764 1.344 1.344.309.58.474 1.154.474 2.74v8.103-2.87l-.006.09a.889.889 0 0 1-1.771-.104V7.11H1.778v4.832c0 .793.082 1.08.237 1.369.155.29.382.518.672.673.268.142.533.224 1.196.235l.174.002h14.242l-2.039-2.038a.888.888 0 0 1 1.257-1.257l3.556 3.556a.889.889 0 0 1 0 1.257l-3.556 3.555a.889.889 0 0 1-1.257-1.257l2.043-2.043c-.12.003-.249.005-.388.005H4.558c-1.585 0-2.16-.165-2.74-.475a3.231 3.231 0 0 1-1.343-1.344c-.31-.58-.475-1.154-.475-2.74V4.559c0-1.585.165-2.16.475-2.74A3.231 3.231 0 0 1 1.819.476C2.399.165 2.973 0 4.559 0h12.216Zm.501 1.778H4.056c-.792 0-1.08.082-1.368.237a1.609 1.609 0 0 0-.673.672c-.142.268-.224.533-.235 1.196l-.002.174v1.276h17.778V4.057c0-.793-.083-1.08-.238-1.369a1.615 1.615 0 0 0-.672-.673c-.29-.155-.578-.237-1.369-.237Z"
                      />
                    </svg>
                  </div>

                  <h3 className="body-font">No-FICO Credit Card</h3>
                  <p>
                    Build your credit score from Day 1. Stay protected and
                    secure from Day 1. Save, invest and spend as you want from
                    Day 1.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon-text-block white-bg">
                  <div className="icon-wrapper">
                    <svg width="23" height="20" fill="none" viewBox="0 0 23 20">
                      <path
                        fill="#1B1A1A"
                        d="M21.13 11.706a1.222 1.222 0 0 0 .838-2.113L12.19.402a1.478 1.478 0 0 0-2.026 0L.385 9.595a1.221 1.221 0 0 0 .835 2.11h1.595V16.2a2.893 2.893 0 0 0 2.892 2.893h4.828v-5.136a.645.645 0 1 1 1.291 0v5.136h4.811A2.893 2.893 0 0 0 19.53 16.2v-4.493h1.602-.001ZM8.608 17.163h-2.9a.964.964 0 0 1-.964-.964V9.777H3.006l8.172-7.68 8.168 7.68H17.6V16.2a.964.964 0 0 1-.964.964h-2.883v-3.207a2.574 2.574 0 1 0-5.148 0v3.207h.002Z"
                      />
                    </svg>
                  </div>

                  <h3 className="body-font">No-Fico Mortgage</h3>
                  <p>
                    Invest in property or buy a home in the U.S. from Day 1.
                  </p>
                </div>
              </div>
              <div className="item">
                <div className="icon-text-block white-bg">
                  <div className="icon-wrapper">
                    <svg width="16" height="21" fill="none" viewBox="0 0 16 21">
                      <path
                        fill="#1B1A1A"
                        d="M16 8.013C16 3.588 12.419 0 8 0 3.581 0 0 3.588 0 8.013c0 3.154 1.938 6.96 5.76 11.48a2.934 2.934 0 0 0 4.48 0c3.822-4.52 5.76-8.326 5.76-11.48ZM8.747 18.482a1.156 1.156 0 0 1-1.63-.136c-3.577-4.23-5.34-7.694-5.34-10.333C1.778 4.57 4.565 1.778 8 1.778s6.222 2.79 6.222 6.235c0 2.639-1.762 6.103-5.34 10.333a1.16 1.16 0 0 1-.135.136ZM4.444 8a3.556 3.556 0 1 1 7.112 0 3.556 3.556 0 0 1-7.112 0Zm1.778 0A1.778 1.778 0 1 0 9.78 8a1.778 1.778 0 0 0-3.557 0Z"
                      />
                    </svg>
                  </div>

                  <h3 className="body-font">
                    U.S. Travel &amp; Health Insurance
                  </h3>
                  <p>
                    Stay secure from Day 1 with pre-departure travel and health
                    insurance.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-pad">
        <div className="container">
          <div className="row">
            <div className="col-md col-8 offset-3">
              <h2 className="heading text-center-mob ">We're all ears.</h2>
              <p className="subhead text-center-mob ">
                There&apos;s no such thing as a stupid question.
              </p>

              <div className="home-faq">
                <Accordion />
              </div>
              <div style={{ marginTop: 40 }}>
                <Button href="/faq/">View all FAQs</Button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Product;
